import { Box, Card, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { GetTenantActionsResponse } from "@safelease/service-utilities";
import { SafeLeaseButton } from "@safelease/components";
import { ImageViewer } from "../../../../../../../shared/ImageViewer";
import { useState } from "react";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useChatBoxStore } from "../../../../ChatBox/useChatBoxStore";
import { useTodoItemListStore } from "../../useTodoItemListStore";
import { useSubmittedClaimDetailPageStore } from "../../../../useSubmittedClaimDetailsPageStore";
import { enqueueSnackbar } from "notistack";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import { TenantActionTypes } from "@safelease/service-utilities/enums";

type SettlementOfferTodoModalProps = {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  tenantAction: GetTenantActionsResponse;
};

function SettlementOfferTodoModal({ modalOpen, setModalOpen, tenantAction }: SettlementOfferTodoModalProps) {
  const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);

  const refetchMessages = useChatBoxStore((state) => state.refetchMessages);
  const refetchTenantActions = useTodoItemListStore((state) => state.refetchTenantActions);
  const refetchClaim = useSubmittedClaimDetailPageStore((state) => state.refetchClaim);

  const responseButtonClicked = async (accepted: boolean) => {
    if (submissionInProgress) return;
    setSubmissionInProgress(true);
    try {
      await ClaimsApi.completeTenantAction(tenantAction?.id as string, {
        [TenantActionTypes.SETTLEMENT_OFFER]: {
          settlementOfferResponse: accepted,
        },
      });
      // Refetch the messages and list of tenant actions to show the updated state of completed
      await refetchMessages();
      await refetchTenantActions();
      // Refetch the claim to show the updated state of the claim after the tenant action is completed
      // There is a possibility that the claim status will change after the tenant action is completed because of an afterUpdate hook on the TenantAction model
      await refetchClaim();
      setSubmissionInProgress(false);
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error, please try again.", {
        variant: "error",
      });
      setSubmissionInProgress(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      onClose={handleClose}
      open={modalOpen}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "#EBEFF7",
          maxWidth: { xs: "80vw", sm: "600px" },
          minWidth: "300px",
          "&:focus": {
            outline: "none",
          },
          padding: 2,
          display: "flex",
          flexDirection: "column",
          overflowWrap: "break-word",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <FiberManualRecordOutlinedIcon />

          <Typography bgcolor={"#031E30"} color={"white"} borderRadius={2} padding={0.5} fontSize={12}>
            Review Settlement Offer
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1, overflow: "auto", marginTop: 2, whiteSpace: "pre-line" }}>
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{tenantAction?.settlementOffer?.message}</Typography>
        </Box>
        <Box sx={{ paddingTop: 2 }}>
          {tenantAction.settlementOffer?.estimateAttachment && (
            <ImageViewer
              file={tenantAction.settlementOffer.estimateAttachment.url}
              fileName={tenantAction.settlementOffer.estimateAttachment.fileName}
            />
          )}
        </Box>
        <Stack spacing={2} direction="row" marginTop={2}>
          {submissionInProgress ? (
            <CircularProgress />
          ) : (
            <>
              <SafeLeaseButton onClick={() => responseButtonClicked(true)} fullWidth size="small" color="navy" variant="contained">
                Accept offer
              </SafeLeaseButton>
              <SafeLeaseButton onClick={() => responseButtonClicked(false)} fullWidth size="small" variant="outlined" color="navy">
                Reject offer
              </SafeLeaseButton>
            </>
          )}
        </Stack>
      </Card>
    </Modal>
  );
}

export { SettlementOfferTodoModal };
