import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { forwardRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";
import { enqueueSnackbar } from "notistack";
import { signIn } from "aws-amplify/auth";
import { useForgotPasswordPageStore, ForgotPasswordStages } from "./ForgotPasswordPages/useForgotPasswordPageStore";

export type SignInFormValues = {
  email: string;
  password: string;
};

const validationSchema = z.object({
  email: z.string().trim().min(1, "Email is required.").email(),
  password: z.string().min(1, "Password is required."),
});

type LoginFormProps = {};

function LoginForm({}: LoginFormProps) {
  const setCurrentFormContextType = useAuthenticationPageStore((state) => state.setCurrentFormContextType);
  const setForgotPasswordStage = useForgotPasswordPageStore((state) => state.setForgotPasswordStage);
  const setNeedsPasswordReset = useAuthenticationPageStore((state) => state.setNeedsPasswordReset);

  const [loading, setLoading] = useState<boolean>(false);
  const { email } = useAuthenticationPageStore((state) => state);

  const useFormMethods = useForm<SignInFormValues>({
    defaultValues: {
      email: email,
      password: "",
    },
    resolver: zodResolver(validationSchema),
  });
  const { formState, handleSubmit } = useFormMethods;

  const onLoginButtonClicked: SubmitHandler<{
    email: string;
    password: string;
  }> = async (data) => {
    setLoading(true);
    try {
      const result = await signIn({
        username: data.email.trim(), // removing all leading and trailing white spaces from the email
        password: data.password,
      });
      if (result?.nextStep?.signInStep === "RESET_PASSWORD") {
        enqueueSnackbar("Please reset your password", { variant: "error" });
        setCurrentFormContextType(FormContentType.FORGOT_PASSWORD);
        setForgotPasswordStage(ForgotPasswordStages.EMAIL_SUBMIT);
      }
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <FormProvider {...useFormMethods}>
      <form style={{ height: "100%" }} onSubmit={handleSubmit(onLoginButtonClicked)}>
        <Stack justifyContent={{ sm: "space-between" }} height="100%" padding={{ xs: 2, sm: 0 }} spacing={{ xs: 3, sm: 0 }}>
          <Stack spacing={{ xs: 1, sm: 2 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Tenant portal login</Typography>
            <StyledSafeLeaseFormTextField
              id="e2e-login-email-input"
              name="email"
              helperText={formState.errors.email?.message}
              error={!!formState.errors.email?.message}
              label="Email"
            />
            <StyledSafeLeaseFormTextField
              id="e2e-login-password-input"
              name="password"
              type="password"
              helperText={formState.errors.password?.message}
              error={!!formState.errors.password?.message}
              label="Password"
            />
            <Typography
              onClick={() => {
                setCurrentFormContextType(FormContentType.FORGOT_PASSWORD);
                setForgotPasswordStage(ForgotPasswordStages.EMAIL_SUBMIT);
                setNeedsPasswordReset(false);
              }}
              sx={{ color: (theme: Theme) => theme.palette.grey.A200, cursor: "pointer", fontSize: 14, fontWeight: 500 }}
            >
              Forgot password?
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <SafeLeaseButton
              id="e2e-login-submit-button"
              type="submit"
              variant="contained"
              color="navy"
              sx={{ width: "100%" }}
              loading={loading}
            >
              Login
            </SafeLeaseButton>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A200, fontSize: 14, fontWeight: 500 }}>
                Don't have an account?
              </Typography>
              <Typography
                onClick={() => setCurrentFormContextType(FormContentType.SIGN_UP)}
                sx={{ color: "#031E30", cursor: "pointer", fontSize: 14, fontWeight: 500 }}
              >
                Create one here
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

const StyledSafeLeaseFormTextField = forwardRef((props: any, _) => {
  return (
    <SafeLeaseFormTextField
      labelProps={{
        sx: {
          fontSize: 14,
          fontWeight: 500,
        },
      }}
      {...props}
    />
  );
});

export { LoginForm };
