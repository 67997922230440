import { Box, CircularProgress, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import React, { useState } from "react";
import { ExpandedPrivatePolicyModal } from "./ExpandedPrivatePolicyModal.tsx";
import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance.ts";
import { Document, Page } from "react-pdf";
import { PrivatePolicyStatusChip } from "./PrivatePolicyStatusChip.tsx";
import PrivatePolicyIcon from "../assets/privatePolicyIcon.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ActionMenu } from "../shared/ActionMenu.tsx";
import { PrivatePolicyUnit } from "./PrivatePolicyUnit.tsx";
import { PrivatePolicyExpirationDate } from "./PrivatePolicyExpirationDate.tsx";
import { getCalculatedPrivatePolicyStatus } from "../utils/helpers/privatePolicy.ts";
import { PrivatePolicyProtectionPlanSelectionModal } from "./PrivatePolicyProtectionPlanSelectionModal.tsx";
import { AlertBanner } from "../shared/AlertBanner.tsx";
import { useNavigate } from "react-router";
import useMixpanel from "../hooks/useMixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";

type PrivatePolicyCardProps = {
  privatePolicy: SafeleasePrivatePolicy;
};

// TODO: This PrivatePolicyCard and ImageViewer are very similar. We should consider refactoring them to share code.
function PrivatePolicyCard({ privatePolicy }: PrivatePolicyCardProps) {
  const navigate = useNavigate();
  const { mixpanelTrack } = useMixpanel();

  const [selected, setSelected] = useState(false);
  const [protectionPlansSelected, setProtectionPlansSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadFailed, setLoadFailed] = useState<boolean>(false);

  const privatePolicyDocument = useQuery({
    queryKey: ["get-ephemeral-link", privatePolicy?.id],
    queryFn: async () => {
      if (!privatePolicy) return null;

      const result = await ClaimsApi.getPrivatePolicyEphemeralLink(`${privatePolicy.id}`);

      if (!result.data) return null;

      const file = result.data.url;
      const fileExtension = file?.split(".")?.pop()?.split("?")[0] ?? "";
      const isPDF = fileExtension.toLowerCase() === "pdf";

      setIsLoading(isPDF);

      return {
        ...result.data,
        isPDF,
      };
    },
    enabled: !!privatePolicy,
  });

  // When a document loads successfully, set the number of pages and stop loading
  const onDocumentLoadSuccess = () => {
    setIsLoading(false);
  };

  // When a document fails to load, stop loading
  const onDocumentLoadFailure = () => {
    setIsLoading(false);
    setLoadFailed(true);
  };

  const archivePrivatePolicy = async () => {
    if (
      window.confirm("Are you sure you want to archive this private policy? By archiving the policy, you will enroll in a SafeLease plan.")
    ) {
      setProtectionPlansSelected(true);
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_MODAL_LAUNCHED_FROM_ARCHIVE_BUTTON_CLICKED, {
        privatePolicyId: privatePolicy.id,
      });
    }
  };

  const downloadPrivatePolicy = async () => {
    if (privatePolicyDocument.data?.url) window.open(privatePolicyDocument.data.url, "_blank");
  };

  const onPrivatePolicyProtectionPlanClicked = () => {
    const { reviewStatus } = getCalculatedPrivatePolicyStatus(privatePolicy);
    if (reviewStatus === "rejected") {
      navigate("/private-policy");
    } else {
      setProtectionPlansSelected(true);
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_MODAL_LAUNCHED_FROM_PROTECTION_PLAN_BUTTON_CLICKED, {
        privatePolicyId: privatePolicy.id,
      });
    }
  };

  const { statusGroup } = getCalculatedPrivatePolicyStatus(privatePolicy);
  const displayPrivatePolicyProtectionPlanCta = ["active", "submitted"].includes(statusGroup);

  const file = privatePolicyDocument.data?.url;
  const isPDF = privatePolicyDocument.data?.isPDF;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "column",
          position: "relative",
          width: 320,
          height: displayPrivatePolicyProtectionPlanCta ? 385 : 320,
          borderRadius: 1,
          overflow: "hidden",
          border: "1px solid",
          borderColor: (theme: Theme) => theme.palette.divider,
          cursor: "zoom-in",
          transition: "150ms ease-in-out",
          "&:hover": {
            boxShadow: "0 0 20px 0 rgba(0,0,0,0.05)",
            borderColor: (theme: Theme) => theme.palette.primary.main,
            filter: "brightness(0.95)",
          },
        }}
      >
        <PrivatePolicyDetailsOverlay
          privatePolicy={privatePolicy}
          archivePrivatePolicy={archivePrivatePolicy}
          downloadPrivatePolicy={downloadPrivatePolicy}
          displayPrivatePolicyProtectionPlanCta={displayPrivatePolicyProtectionPlanCta}
          onPrivatePolicyProtectionPlanClicked={onPrivatePolicyProtectionPlanClicked}
        />
        <Box onClick={() => setSelected(true)}>
          {/* Loading state */}
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}

          {/* Display non-PDF */}
          {file && !isPDF && (
            <img
              src={file}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          )}

          {/* Display PDF */}
          {file && isPDF && !loadFailed && (
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => {
                console.log({ error });
                onDocumentLoadFailure();
              }}
            >
              <Page width={320} pageNumber={1} />
            </Document>
          )}
        </Box>
      </Box>
      {selected && (
        <ExpandedPrivatePolicyModal
          privatePolicy={privatePolicy}
          handleClose={() => setSelected(false)}
          privatePolicyDocument={privatePolicyDocument.data}
          archivePrivatePolicy={archivePrivatePolicy}
          displayPrivatePolicyProtectionPlanCta={displayPrivatePolicyProtectionPlanCta}
          onPrivatePolicyProtectionPlanClicked={onPrivatePolicyProtectionPlanClicked}
        />
      )}
      {protectionPlansSelected && (
        <PrivatePolicyProtectionPlanSelectionModal privatePolicy={privatePolicy} handleClose={() => setProtectionPlansSelected(false)} />
      )}
    </>
  );
}

function PrivatePolicyDetailsOverlay({
  privatePolicy,
  archivePrivatePolicy,
  downloadPrivatePolicy,
  displayPrivatePolicyProtectionPlanCta,
  onPrivatePolicyProtectionPlanClicked,
}: {
  privatePolicy: SafeleasePrivatePolicy;
  archivePrivatePolicy: () => void;
  downloadPrivatePolicy: () => void;
  displayPrivatePolicyProtectionPlanCta: boolean;
  onPrivatePolicyProtectionPlanClicked: () => void;
}) {
  const { reviewStatus, isEnrollmentEnabled } = getCalculatedPrivatePolicyStatus(privatePolicy);
  const actionMenuOptions = [{ label: "Download", cb: () => downloadPrivatePolicy() }];

  if (isEnrollmentEnabled) {
    actionMenuOptions.push({ label: "Remove", cb: () => archivePrivatePolicy() });
  }

  const isRejected = reviewStatus === "rejected";

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        position: "absolute",
        px: 1,
        pt: 2,
        pb: displayPrivatePolicyProtectionPlanCta ? 1 : 2,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        bgcolor: (theme: Theme) => theme.palette.blueGray.main,
        color: (theme: Theme) => theme.palette.navy.main,
        cursor: "auto",
      }}
    >
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <img src={PrivatePolicyIcon} alt="private policy icon" />
            <Typography
              variant="subtitle1"
              fontWeight="500"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                maxWidth: 100,
              }}
            >
              {privatePolicy.policyNumber}
            </Typography>
            <Tooltip title="The private policy number">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon sx={{ color: "grey", cursor: "pointer", fontSize: 19 }} />
              </Box>
            </Tooltip>
            <PrivatePolicyStatusChip privatePolicy={privatePolicy} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <ActionMenu dropdownOptions={actionMenuOptions} />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <PrivatePolicyUnit privatePolicy={privatePolicy} fontSize={15} hideTooltip />
          <PrivatePolicyExpirationDate privatePolicy={privatePolicy} fontSize={15} hideTooltip />
        </Stack>
        {displayPrivatePolicyProtectionPlanCta && (
          <AlertBanner
            type={isRejected ? "error" : "warning"}
            message={
              isRejected ? (
                <>Your private policy for Unit P06 got rejected. Please re-submit the policy.</>
              ) : (
                <>
                  {`Your belongings ${privatePolicy.policyDeductible >= 200000 ? "are not" : "may not be"} covered, get `}
                  <u>
                    <strong>supplemental coverage</strong>
                  </u>{" "}
                  today.
                </>
              )
            }
            onClick={onPrivatePolicyProtectionPlanClicked}
          />
        )}
      </Box>
    </Stack>
  );
}

export { PrivatePolicyCard };
