import { Box, Stack, Typography } from "@mui/material";
import safeleaseLogo from "../assets/safelease_logoicon_rgb_whiteonnavyblue.svg";
import topLeftLoginBackgroundImage from "../assets/topLeftLoginBackgroundImage.png";
import bottomRightBackgroundImage from "../assets/bottomRightBackgroundImage.png";
import blueWaveBackground from "../assets/blueWaveBackground.png";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";
import { LoginForm } from "./LoginForm";
import { ForgotPasswordPages } from "./ForgotPasswordPages";
import { PasswordlessSignUpForm } from "./PasswordlessSignUpForm";

/**
 * This component is the main component that renders when there is no current authenticated user
 * It is the parent component to all three sets of forms that can be rendered
 * The three different states are SignUpForm, LoginForm, and ForgotPasswordPages
 */
type AuthenticationPageProps = {};

function AuthenticationPage({}: AuthenticationPageProps) {
  const currentFormContentType = useAuthenticationPageStore((state) => state.currentFormContentType);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: { xs: "grey.900", sm: "#152744" },
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <img style={{ top: 0, left: 0, position: "absolute" }} src={topLeftLoginBackgroundImage} />
        <img style={{ bottom: 0, right: 0, position: "absolute" }} src={bottomRightBackgroundImage} />
      </Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          width: { xs: "100%", sm: "95%" },
          maxWidth: 1000,
          minWidth: 300,
          height: { xs: "100%", sm: 543 },
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: { xs: 0, sm: 3 },
          padding: { xs: 0, sm: 3 },
          zIndex: 4,
        }}
        spacing={{ xs: 0, sm: 3 }}
      >
        <Stack
          padding={{ xs: 1, sm: 3 }}
          justifyContent="space-between"
          sx={{
            width: { xs: "100%", sm: 500 },
            height: { xs: 250, sm: "100%" },
            bgcolor: "rgba(21, 39, 68, 1)",
            borderRadius: { xs: 0, sm: 3 },
            backgroundImage: `url(${blueWaveBackground})`,
            backgroundSize: { xs: "cover", sm: "100% 100%" },
            backgroundPosition: { xs: "center", sm: "center" },
          }}
        >
          <Stack
            spacing={1}
            sx={{
              margin: { xs: 1, sm: 0 },
              marginTop: { xs: 4, sm: 0 },
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: 18, color: "rgba(255, 255, 255, 1)" }}>
              File a Claim or Opt-Out of your Facility's Coverage Plan
            </Typography>
            <Typography sx={{ fontSize: 11, color: "rgba(255, 255, 255, 1)" }}>
              Have you experienced damage or loss to your personal property stored in your self storage unit? You’ve come to the right
              place! SafeLease is here to help you get compensated for covered losses quickly and easily. Simply use this portal to submit
              your claim.
              <br />
              <br />
              Many self storage leases require coverage for a tenant's stored property. Unfortunately, a homeowners or renters insurance
              policy may not provide adequate coverage for hazards that often occur at self storage facilities.
              <br />
              <br />
              If you have a policy that provides coverage for your personal property in storage and you’d like to use that policy to opt out
              of the coverage plan offered through your facility, you can do that! In this portal, you can easily upload proof that your
              stored belongings are protected by another policy. You can even enroll into supplemental coverage through a SafeLease plan as
              well!
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1} margin={{ xs: 1, sm: 0 }} paddingBottom={{ xs: 2, sm: 0 }}>
            <Box sx={{ width: 25, height: 25 }}>
              <img src={safeleaseLogo} />
            </Box>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: "white" }}>SafeLease Tenant Portal</Typography>
          </Stack>
        </Stack>

        <Box sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
          {currentFormContentType === FormContentType.SIGN_UP && <PasswordlessSignUpForm />}
          {currentFormContentType === FormContentType.LOGIN && <LoginForm />}
          {currentFormContentType === FormContentType.FORGOT_PASSWORD && <ForgotPasswordPages />}
        </Box>
      </Stack>
      <Stack direction="row" marginTop={5}>
        <Typography sx={{ fontSize: 12, color: "rgba(255, 255, 255, 1)" }}>
          By continuing, you agree to the SafeLease{" "}
          <u>
            <a style={{ color: "rgba(255, 255, 255, 1)" }} href="https://info.safelease.com/terms-of-service" target="_blank">
              Terms of Service
            </a>
          </u>{" "}
          and{" "}
          <u>
            <a style={{ color: "rgba(255, 255, 255, 1)" }} href="https://info.safelease.com/safelease-privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </u>
          .
        </Typography>
      </Stack>
    </Box>
  );
}

export { AuthenticationPage };
