import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";

type DocumentCardProps = {
  docTitle: string | undefined;
  isProvided: boolean;
  handleAddButtonClicked?: () => void;
};

function DocumentCard({ docTitle, isProvided, handleAddButtonClicked }: DocumentCardProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderRadius: 1,
        border: "1px solid #d3d3d3",
        height: "50px",
        width: 300,
        mr: 1,
        mt: 1,
        pr: 1,
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ overflow: "hidden", flexGrow: 1 }}>
        <InsertDriveFileOutlinedIcon sx={{ m: 1, color: isProvided ? "green.main" : "red.main" }} />
        <Typography
          sx={{
            fontSize: ".9rem",
            fontWeight: 600,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {docTitle}
        </Typography>
      </Stack>
      {!isProvided && (
        <SafeLeaseButton variant="contained" color="navy" sx={{ height: "25px", flexShrink: 0 }} onClick={handleAddButtonClicked}>
          Add
        </SafeLeaseButton>
      )}
    </Stack>
  );
}

export { DocumentCard };
