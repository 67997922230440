import { Stack, Typography } from "@mui/material";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { DocumentCard } from "./DocumentCard";

type ClaimDocumentSectionProps = {
  attachments: Attachment_ClaimsDbModel[];
  label: string;
  handleAddButtonClicked?: () => void;
};

function ClaimDocumentSection({ attachments, label, handleAddButtonClicked }: ClaimDocumentSectionProps) {
  return (
    <Stack direction="column">
      <Typography fontSize={".8rem"} fontWeight={600}>
        {label}
      </Typography>
      {attachments.length === 0 ? (
        <DocumentCard docTitle={"No documents provided"} isProvided={false} handleAddButtonClicked={handleAddButtonClicked} />
      ) : (
        <Stack direction={"row"} flexWrap={"wrap"} minWidth={400} alignContent={"center"}>
          {attachments.map((attachment) => (
            <DocumentCard key={attachment.id} docTitle={attachment.fileName} isProvided={true} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export { ClaimDocumentSection };
