import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useChatBoxStore } from "../../../useChatBoxStore";
import { useTodoItemListStore } from "../../../../ToDoItemsContainer/ToDoItemList/useTodoItemListStore";
import { useSubmittedClaimDetailPageStore } from "../../../../useSubmittedClaimDetailsPageStore";
import { enqueueSnackbar } from "notistack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ImageViewer } from "../../../../../../../shared/ImageViewer";
import { TenantActionTypes } from "@safelease/service-utilities/enums";

type SettlementOfferMessageProps = {
  messageData: GetClaimMessageResponse;
};

function SettlementOfferMessage({ messageData }: SettlementOfferMessageProps) {
  const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);

  const refetchMessages = useChatBoxStore((state) => state.refetchMessages);
  const refetchTenantActions = useTodoItemListStore((state) => state.refetchTenantActions);
  const refetchClaim = useSubmittedClaimDetailPageStore((state) => state.refetchClaim);

  const responseButtonClicked = async (accepted: boolean) => {
    if (submissionInProgress) return;
    setSubmissionInProgress(true);
    try {
      await ClaimsApi.completeTenantAction(messageData.tenantAction?.id as string, {
        [TenantActionTypes.SETTLEMENT_OFFER]: {
          settlementOfferResponse: accepted,
        },
      });
      // Refetch the messages and list of tenant actions to show the updated state of completed
      await refetchMessages();
      await refetchTenantActions();
      // Refetch the claim to show the updated state of the claim after the tenant action is completed
      // There is a possibility that the claim status will change after the tenant action is completed because of an afterUpdate hook on the TenantAction model
      await refetchClaim();
      setSubmissionInProgress(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error, please try again.", {
        variant: "error",
      });
      setSubmissionInProgress(false);
    }
  };

  return (
    <>
      {/* Message Content Section */}
      <Stack spacing={1} bgcolor={"#EBEFF7"} padding={2} borderRadius={4} sx={{ maxWidth: { xs: "80vw", sm: 800 } }}>
        {/* Conditional rendering of the status icon based on tenant's acceptance of the settlement offer & text styling beside */}
        <Stack direction="row" alignItems="center" spacing={1}>
          {messageData.tenantAction?.settlementOffer?.tenantAccepted ? (
            <CheckCircleOutlineIcon fontSize="small" color="success" />
          ) : messageData.tenantAction?.settlementOffer?.tenantAccepted === false ? (
            <CancelOutlinedIcon color="error" />
          ) : (
            <FiberManualRecordOutlinedIcon />
          )}

          <Typography bgcolor={"#031E30"} color={"white"} borderRadius={2} padding={0.5} fontSize={12}>
            Review Settlement Offer
          </Typography>
        </Stack>
        <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap" }}>
          {messageData.tenantAction?.settlementOffer?.message}
        </Typography>

        {/* Conditional rendering of the action buttons if the tenant action is not completed */}
        {!messageData.tenantAction?.completed && (
          <Stack spacing={2} direction="row">
            {submissionInProgress ? (
              <CircularProgress />
            ) : (
              <>
                <SafeLeaseButton onClick={() => responseButtonClicked(true)} size="small" fullWidth variant="contained" color="navy">
                  Accept {formatCurrency(Number(messageData.tenantAction?.settlementOffer?.amount) / 100)}
                </SafeLeaseButton>
                <SafeLeaseButton onClick={() => responseButtonClicked(false)} size="small" fullWidth variant="outlined" color="navy">
                  Reject offer
                </SafeLeaseButton>
              </>
            )}
          </Stack>
        )}

        {/* Conditional rendering of the ImageViewer if there is an estimate attachment*/}
        <Box>
          {messageData.tenantAction?.settlementOffer?.estimateAttachment && (
            <ImageViewer
              file={messageData.tenantAction?.settlementOffer.estimateAttachment.url}
              fileName={messageData.tenantAction?.settlementOffer.estimateAttachment.fileName}
            />
          )}
        </Box>
      </Stack>
    </>
  );
}

export { SettlementOfferMessage };
