import { Box, Divider, Stack, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GooglePlacesAutocomplete, PlaceType } from "../../../shared/GooglePlacesAutoComplete";
import { useState, useEffect } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { GetSafeleaseLocationForGooglePlaceIdResponse } from "@safelease/service-utilities";
import { BorderedCheckbox } from "../../../shared/BorderedCheckbox";
import * as Sentry from "@sentry/react";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanel";
import useMixpanel from "../../../hooks/useMixpanel";

type LocationSearchStepProps = {
  doNotAllowSkip?: boolean;
};

function LocationSearchStep({ doNotAllowSkip = false }: LocationSearchStepProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { mixpanelTrack } = useMixpanel();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilityLocationUserInput, setFacilityLocationUserInput] = useState<PlaceType | null>(null);
  const {
    setSelectedLocation,
    setCurrentStep,
    setProgress,
    setUserProvidedGooglePlaceId,
    setUserProvidedAddressDetail,
    setVerifySkipModalOpen,
  } = useUnitSelectionModalStore((state) => ({
    setSelectedLocation: state.setSelectedLocation,
    setCurrentStep: state.setCurrentStep,
    setProgress: state.setProgress,
    setUserProvidedGooglePlaceId: state.setUserProvidedGooglePlaceId,
    setUserProvidedAddressDetail: state.setUserProvidedAddressDetail,
    setVerifySkipModalOpen: state.setVerifySkipModalOpen,
  }));
  const [matchedLocation, setMatchedLocation] = useState<GetSafeleaseLocationForGooglePlaceIdResponse | null>(null);
  const [matchedLocationSelected, setMatchedLocationSelected] = useState<boolean>(false);
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);
  const [skipButtonMessage, setSkipButtonMessage] = useState<string>("");

  useEffect(() => {
    if (facilityLocationUserInput) {
      onSearchButtonClick();
    }
  }, [facilityLocationUserInput]);

  const onSearchButtonClick = async () => {
    if (!facilityLocationUserInput) return; // have this explicit if to suppress TS error, should always be true because we disable the button that calls this function if it's not
    setMatchedLocationSelected(false);
    setMatchedLocation(null);
    setSelectedLocation(null);
    setUserProvidedAddressDetail(null);
    setShowSkipButton(false);
    setSkipButtonMessage("");
    setIsLoading(true);

    mixpanelTrack(MIXPANEL_EVENTS.LOCATION_SEARCH_STEP_SUBMITTED, {
      placeId: facilityLocationUserInput.place_id,
    });

    try {
      const response = await ClaimsApi.getSafeleaseLocationForGooglePlaceId(facilityLocationUserInput.place_id);
      const matchedLocation = response.data;
      setMatchedLocation(matchedLocation);
      setUserProvidedAddressDetail(matchedLocation.userProvidedAddressDetail);
    } catch (error: any) {
      if (error.response?.data?.type === "LocationNotFound") {
        // not an actual error. This just means we didn't find a location for the google place id the user provided
        setSkipButtonMessage(
          `We did not find a facility for the address you provided. Please ensure that you have entered the address of your storage facility.${doNotAllowSkip ? "" : " If you are certain the address is correct then click the button below."}`,
        );
        setShowSkipButton(true);
        setUserProvidedAddressDetail(error.response?.data?.userProvidedAddressDetail);
        mixpanelTrack(MIXPANEL_EVENTS.LOCATION_SEARCH_STEP_SUBMITTED_FAILED_TO_MATCH, {
          placeId: facilityLocationUserInput.place_id,
          address: error.response?.data?.userProvidedAddressDetail?.address,
          city: error.response?.data?.userProvidedAddressDetail?.city,
          state: error.response?.data?.userProvidedAddressDetail?.state,
        });
      } else if (error.response?.data?.type === "BadRequest") {
        enqueueSnackbar(
          "There was an error searching for your location. Please ensure you have entered a valid storage facility address.",
          {
            variant: "error",
          },
        );
      } else {
        Sentry.captureException(error);
        enqueueSnackbar("There was an error searching for your location. Please try again", { variant: "error" });
      }
    } finally {
      setIsLoading(false);
      setUserProvidedGooglePlaceId(facilityLocationUserInput.place_id); // store the google place id that the user provided to be used for populating user provided location details in the database when we create the unit at the end of the matching experience
    }
  };

  const onContinueButtonClick = () => {
    setSelectedLocation(matchedLocation);
    setCurrentStep(UnitSelectionModalStep.EMAIL_UNIT_LOOKUP);
    setProgress(50);
  };

  return (
    <Stack spacing={3} width="100%">
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>Find your facility</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: (theme: Theme) => theme.palette.grey.A200 }}>
          {"Enter your storage facility’s name/address below. This is not your personal home address."}
        </Typography>
      </Stack>
      <Stack direction={isMobile ? "column" : "row"} spacing={{ xs: 1, md: 2 }}>
        <Box sx={{ width: "100%" }}>
          <GooglePlacesAutocomplete
            placeholder="Enter facility name/address"
            textFieldSx={{
              "& .MuiInputBase-root": {
                color: "black", // Custom text color
                padding: "1px 12px", // custom padding making it appear less tall
                fontSize: 15,
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#1E2A38", // Custom border color when focused
                },
              },
            }}
            value={facilityLocationUserInput}
            setValue={setFacilityLocationUserInput}
          />
        </Box>
        <SafeLeaseButton
          loading={isLoading}
          disabled={!facilityLocationUserInput}
          onClick={onSearchButtonClick}
          color="navy"
          variant="contained"
        >
          Search
        </SafeLeaseButton>
      </Stack>
      {/* Successfully matched a facility */}
      {matchedLocation && (
        <Stack spacing={1.5}>
          <Divider />
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>👋🏼 We found a matching address</Typography>
          <BorderedCheckbox
            value={matchedLocationSelected}
            setValue={setMatchedLocationSelected}
            label={`${matchedLocation.address} ${matchedLocation.city}, ${matchedLocation.state} ${matchedLocation.postalCode}`}
          />
          {/* if the showSkipButton value is false show the optional text button in case the matched location isn't actually the one the tenant intended to file a claim for */}
          {!showSkipButton && !matchedLocationSelected && (
            <Typography
              onClick={() => {
                setShowSkipButton(true);
                setSkipButtonMessage(
                  `Try searching a different address${doNotAllowSkip ? "" : " or skip unit matching by clicking the button below"}`,
                );
              }}
              sx={{
                color: (theme: Theme) => theme.palette.grey.A200,
                cursor: "pointer",
                fontSize: 14,
                fontWeight: 500,
                alignSelf: "center",
              }}
            >
              This is not my facility
            </Typography>
          )}
        </Stack>
      )}
      {matchedLocationSelected ? (
        <SafeLeaseButton onClick={onContinueButtonClick} color="navy" variant="contained">
          Continue
        </SafeLeaseButton>
      ) : (
        showSkipButton && (
          <Stack spacing={1} alignItems="center">
            <Typography sx={{ color: "#2e3b94", fontSize: 14, fontWeight: 500 }}>{skipButtonMessage}</Typography>
            {!doNotAllowSkip && (
              <SafeLeaseButton onClick={() => setVerifySkipModalOpen(true)} variant="outlined" color="navy" size="small">
                Skip unit matching
              </SafeLeaseButton>
            )}
          </Stack>
        )
      )}
    </Stack>
  );
}

export { LocationSearchStep };
