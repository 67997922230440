import { Box, Typography } from "@mui/material";

type HorizontalClaimSummaryBarProps = {
  unitNumber?: string;
  totalClaimedItemCount?: number;
  totalRequestedAmount: string;
  lossDescription?: string;
};

function HorizontalClaimSummaryBar({
  unitNumber,
  totalClaimedItemCount,
  totalRequestedAmount,
  lossDescription,
}: HorizontalClaimSummaryBarProps) {
  const summaryItems = [
    { label: "Unit", value: unitNumber },
    { label: "Claimed Items", value: totalClaimedItemCount },
    { label: "Total Claim Amount", value: totalRequestedAmount },
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", mt: 2 }}>
        {summaryItems.map(({ label, value }) => (
          <Box key={label} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography sx={{ fontSize: ".8rem", whiteSpace: "nowrap" }}>{value}</Typography>
            <Typography sx={{ fontSize: ".8rem", whiteSpace: "nowrap" }}>{label}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", mt: 2, mb: 2 }}>
        <Typography sx={{ fontSize: ".8rem", fontWeight: 600 }}>Your Description</Typography>
        <Typography sx={{ fontSize: ".8rem", marginTop: 1, color: "grey.600", wordWrap: "break-word", width: "100%" }}>
          {lossDescription}
        </Typography>
      </Box>
    </>
  );
}

export { HorizontalClaimSummaryBar };
