import { useState } from "react";
import { FormQuestionDef_ClaimsDbModel } from "@safelease/service-utilities";
import { Accordion, AccordionSummary, AccordionDetails, Stack, Typography, Box } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ResponseDisplay } from "./ResponseDisplay";
import { useClaimCreationStore } from "../../useClaimCreationStore";
import { CreationSteps } from "../../../../../enums";

type UserQuestionsSummaryProps = {
  formQuestions: FormQuestionDef_ClaimsDbModel[];
};

const UserQuestionsSummary = ({ formQuestions }: UserQuestionsSummaryProps) => {
  const [expandedQuestions, setExpandedQuestions] = useState<boolean>(false);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);

  const isQuestionAnswered = (question: FormQuestionDef_ClaimsDbModel) =>
    question.formQuestionResponses && question.formQuestionResponses.length > 0;

  const areAllQuestionsAnswered = formQuestions.every(isQuestionAnswered);

  const sortedQuestions = [...formQuestions].sort((a, b) => Number(isQuestionAnswered(a)) - Number(isQuestionAnswered(b)));

  const handleQuestionSelect = (question: FormQuestionDef_ClaimsDbModel) => {
    if (!isQuestionAnswered(question)) {
      setCreationStep(CreationSteps.UserQuestions);
    }
  };

  return (
    <Accordion
      expanded={expandedQuestions}
      onChange={(_, isExpanded) => setExpandedQuestions(isExpanded)}
      sx={{
        borderRadius: 1,
        border: "1px solid #d3d3d3",
        width: { xs: 300, sm: "90%" },
        marginRight: 1,
        marginTop: 2,
        boxShadow: "none",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingLeft: 1 }}>
        <Stack spacing={1} direction="row" alignItems="center">
          {areAllQuestionsAnswered ? (
            <CheckCircleOutlinedIcon sx={{ color: "green.main" }} />
          ) : (
            <QuestionMarkIcon sx={{ color: "red.main" }} />
          )}
          <Typography sx={{ fontSize: ".9rem", fontWeight: 600 }}>Questions Summary</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {sortedQuestions.map((question) => (
            <Box
              key={question.id}
              onClick={() => handleQuestionSelect(question)}
              sx={{
                cursor: isQuestionAnswered(question) ? "default" : "pointer",
                mb: 1,
                opacity: isQuestionAnswered(question) ? 0.65 : 1,
              }}
            >
              <Stack direction="row" alignItems="center">
                {isQuestionAnswered(question) ? (
                  <CheckCircleOutlinedIcon sx={{ marginRight: 1, color: "green.main" }} />
                ) : (
                  <QuestionMarkIcon sx={{ marginRight: 1, color: "red.main" }} />
                )}
                <Typography sx={{ fontSize: ".9rem", fontWeight: 600 }}>{question.question}</Typography>
              </Stack>
              {isQuestionAnswered(question) && <ResponseDisplay question={question} />}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export { UserQuestionsSummary };
