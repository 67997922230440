import * as Sentry from "@sentry/react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { appConfig } from "../config/app.config";
import { mixpanelEventHandler } from "../utils/mixpanel";

const useMixpanel = () => {
  const location = useLocation();

  const mixpanelTrack = useCallback(
    (eventName: string, eventProperties: any = {}) => {
      if (!eventName) return;

      try {
        addNavigationContextToEventProperties(eventProperties, location);
        mixpanelEventHandler(eventName, eventProperties);
      } catch (error) {
        console.log("Mixpanel track error: ", error);
        if (appConfig.environment === "production") Sentry.captureException(error);
      }
    },
    [location],
  );

  return { mixpanelTrack };
};

function addNavigationContextToEventProperties(eventProperties: any, location: any) {
  if (!location) return;
  eventProperties.pathname = location.pathname;
  eventProperties.search = location.search;
  eventProperties.hash = location.hash;
  eventProperties.state = location.state;
  eventProperties.key = location.key;
}

export default useMixpanel;
