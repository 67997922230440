import { fileTypeFromBuffer, FileTypeResult } from "file-type";

export async function validateFileType(
  file: File,
  fileTypes: string[] = ["pdf", "jpg", "jpeg", "png", "gif", "docx", "doc", "webp"],
  mimeTypes: string[] = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "image/webp",
  ],
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = () => {
        if (!(reader.result instanceof ArrayBuffer)) {
          reject("Invalid file buffer");
          return;
        }

        // Returns true if the file is a file from the accepted list
        fileTypeFromBuffer(reader.result).then((result: FileTypeResult | undefined) => {
          if (result && fileTypes.includes(result.ext)) {
            resolve(true);
          } else {
            // Fallback to using mime type
            const mimeType = file.type;
            resolve(mimeTypes.includes(mimeType));
          }
        });
      };

      reader.readAsArrayBuffer(file);
    } catch (error: any) {
      console.error("Error validating file: ", error.message);
      resolve(false);
    }
  });
}
