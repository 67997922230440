import { fetchAuthSession, signOut } from "aws-amplify/auth";
import dayjs from "dayjs";
import { create } from "zustand";

export interface UserAttributes {
  sub: string;
  email: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  phone_number_verified: string;
  email_verified: string;
  textCommsAccepted: string;
  isDebuggingEnabled: string;
  needsPasswordReset: string;
}

type AuthStore = {
  userAttributes: UserAttributes | null;
  setUserAttributes: (userAttributes: UserAttributes | null) => void;
};

export const useAuthStore = create<AuthStore>()((set) => ({
  userAttributes: null,
  setUserAttributes: (userAttributes) => {
    set({
      userAttributes,
    });
  },
}));

// this is an exported utility function to be shared by the different apiInstances
export async function getValidToken() {
  const session = await fetchAuthSession();
  if (!session.tokens || !session.tokens.accessToken.payload.exp) throw new Error("Failed to fetch session");
  const expiresAt = dayjs.unix(session.tokens.accessToken.payload.exp!);
  const now = dayjs();
  const minutesUntilExpiration = expiresAt.diff(now, "minute");
  if (minutesUntilExpiration < 3) {
    // if the token is about to expire in less than 3 minutes, refresh it
    const refreshedSession = await fetchAuthSession({ forceRefresh: true });
    if (!refreshedSession.tokens || !refreshedSession.tokens.accessToken.payload.exp) throw new Error("Failed to fetch refreshed session");
    return refreshedSession.tokens.accessToken.toString();
  }
  return session.tokens.accessToken.toString();
}

export function logout() {
  signOut();
  window.location.href = "/";
}
