import { Box, Card, Stack, Typography } from "@mui/material";
import { useTenantActionModalStore } from "../useTenantActionModalStore";
import { TextResponseSubmissionSection } from "./TextResponseSubmissionSection";
import { BooleanResponseSubmissionSection } from "./BooleanResponseSubmissionSection";
import { DateResponseSubmissionSection } from "./DateResponseSubmissionSection";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";
import { useChatBoxStore } from "../../ChatBox/useChatBoxStore";
import { useTodoItemListStore } from "../../ToDoItemsContainer/ToDoItemList/useTodoItemListStore";
import { useState } from "react";
import { TenantActionTypes } from "@safelease/service-utilities/enums";

type FormQuestionResponseModalContentProps = {};

function FormQuestionResponseModalContent({}: FormQuestionResponseModalContentProps) {
  const { modalContentData, resetStore } = useTenantActionModalStore();
  const refetchMessages = useChatBoxStore((state) => state.refetchMessages);
  const refetchTenantActions = useTodoItemListStore((state) => state.refetchTenantActions);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const handleResponseSubmission = async (answer: string) => {
    if (!modalContentData) return;
    setRequestLoading(true);
    try {
      await ClaimsApi.completeTenantAction(modalContentData?.id, {
        [TenantActionTypes.FORM_QUESTION_ANSWER]: { formQuestionResponse: answer },
      });
      refetchMessages();
      refetchTenantActions();
      setRequestLoading(false);
      resetStore();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to submit response, please try again.", { variant: "error" });
      setRequestLoading(false);
    }
  };
  return (
    <Card sx={{ width: 600, padding: 2 }}>
      <Stack spacing={2} paddingTop={1}>
        <Box sx={{ maxHeight: 350, overflow: "auto" }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              whiteSpace: "pre-wrap", // make sure new lines are displayed
              overflowWrap: "break-word",
            }}
          >
            {modalContentData?.formQuestionDef?.question}
          </Typography>
        </Box>

        {modalContentData?.formQuestionDef?.questionType === "string" && (
          <TextResponseSubmissionSection handleResponseSubmission={handleResponseSubmission} requestLoading={requestLoading} />
        )}
        {modalContentData?.formQuestionDef?.questionType === "date" && (
          <DateResponseSubmissionSection handleResponseSubmission={handleResponseSubmission} requestLoading={requestLoading} />
        )}
        {modalContentData?.formQuestionDef?.questionType === "boolean" && (
          <BooleanResponseSubmissionSection handleResponseSubmission={handleResponseSubmission} requestLoading={requestLoading} />
        )}
      </Stack>
    </Card>
  );
}

export default FormQuestionResponseModalContent;
