import { Typography, Stack } from "@mui/material";
import { penniesToDollars } from "../../../../../utils/formatters";
import { LineItem_ClaimsDbModel } from "@safelease/service-utilities";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { DocumentCard } from "../DocumentCard";
import { SafeLeaseButton } from "@safelease/components";
import { useClaimCreationStore } from "../../useClaimCreationStore";
import { CreationSteps } from "../../../../../enums";

type LineItemsSummaryProps = {
  lineItems: LineItem_ClaimsDbModel[];
};

function LineItemsSummary({ lineItems }: LineItemsSummaryProps) {
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);

  const navigateToLineItemListStep = () => {
    setCreationStep(CreationSteps.LineItemsList);
  };

  return (
    <>
      {lineItems.map((lineItem) => {
        const proofOfOwnershipFiles = lineItem.proofOfOwnershipFiles?.filter((file) => file.attachment && !file.attachment.deletedAt);
        const proofOfPurchaseFiles = lineItem.proofOfPurchaseFiles?.filter((file) => file.attachment && !file.attachment.deletedAt);
        return (
          <Stack
            key={lineItem.id}
            sx={{
              border: "1px solid rgb(229, 229, 229, 0.9)",
              mb: 2,
              minWidth: 350,
              position: "relative",
            }}
          >
            <SafeLeaseButton
              onClick={navigateToLineItemListStep}
              variant="contained"
              sx={{
                bgcolor: "transparent",
                color: "black",
                border: "1px solid black",
                position: "absolute",
                top: "1rem",
                right: "1rem",
                height: "2rem",
              }}
            >
              Edit
            </SafeLeaseButton>
            <Stack>
              <Stack direction="column">
                {/* Title with error or checkmark Icon w/ claim ammount */}

                <Typography sx={{ m: 1, fontSize: "1.25rem", fontWeight: 700, display: "flex", alignItems: "center" }}>
                  {proofOfPurchaseFiles?.length && proofOfOwnershipFiles?.length ? (
                    <CheckCircleOutlineOutlinedIcon sx={{ color: "green.main", mr: 1 }} />
                  ) : (
                    <ErrorIcon sx={{ color: "red.main", mr: 1 }} />
                  )}
                  {lineItem.name}
                </Typography>

                <Typography sx={{ ml: 1, fontSize: ".75rem" }}>Claim amount: {penniesToDollars(lineItem.requestedAmount)}</Typography>

                {/* ****Provided Files Section**** */}

                <Stack sx={{ p: 1 }}>
                  {proofOfOwnershipFiles?.length || proofOfPurchaseFiles?.length ? (
                    <Typography sx={{ fontSize: ".8rem", fontWeight: 600 }}>Provided Documents</Typography>
                  ) : null}

                  <Stack direction={{ xs: "column", md: "row" }} useFlexGap flexWrap={"wrap"}>
                    {proofOfOwnershipFiles?.map((file) => (
                      <DocumentCard key={`ownership-${file.id}`} docTitle={file?.attachment?.fileName} isProvided={true} />
                    ))}
                    {proofOfPurchaseFiles?.map((file) => (
                      <DocumentCard key={`purchase-${file.id}`} docTitle={file?.attachment?.fileName} isProvided={true} />
                    ))}
                  </Stack>
                </Stack>
              </Stack>

              {/* **** Missing Documents Section **** */}

              <Stack sx={{ p: 1 }}>
                {!proofOfPurchaseFiles?.length || !proofOfOwnershipFiles?.length ? (
                  <Typography sx={{ fontSize: ".8rem", fontWeight: 600 }}>Missing Documents</Typography>
                ) : null}

                <Stack direction={{ xs: "column", md: "row" }}>
                  {!proofOfOwnershipFiles?.length && (
                    <DocumentCard docTitle="Proof of Ownership" isProvided={false} handleAddButtonClicked={navigateToLineItemListStep} />
                  )}
                  {!proofOfPurchaseFiles?.length && (
                    <DocumentCard docTitle="Proof of Purchase" isProvided={false} handleAddButtonClicked={navigateToLineItemListStep} />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </>
  );
}
export { LineItemsSummary };
