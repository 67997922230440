import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useClaimCreationStore } from "../useClaimCreationStore";
import { useQuery } from "@tanstack/react-query";
import { FormQuestions } from "./FormQuestions";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { ActionButtonsFooter } from "../ActionButtonsFooter";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { FormQuestionDef_ClaimsDbModel } from "@safelease/service-utilities";
import { HelpIcon } from "../HelpIcon";
import { useParams } from "react-router-dom";

type UserQuestionsProps = {};

function UserQuestions({}: UserQuestionsProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const [continueRequestPending, setContinueRequestPending] = useState<boolean>(false);
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);
  const formQuestionResponses = useClaimCreationStore((state) => state.formQuestionResponses);

  const continueToNextStep = async () => {
    if (!formQuestionResponses || creationStep === null) {
      return;
    }
    setContinueRequestPending(true);
    try {
      await ClaimsApi.createFormQuestionResponses(formQuestionResponses, claimId);
      await ClaimsApi.updateClaim(claimId, {
        creationStep: creationStep + 1,
      });
      setContinueRequestPending(false);
      setCreationStep(creationStep + 1);
    } catch (err) {
      enqueueSnackbar("Error uploading protection claim type, please try again.", { variant: "error" });
      setContinueRequestPending(false);
    }
  };

  const formQuestionDefsQuery = useQuery<FormQuestionDef_ClaimsDbModel[]>({
    queryKey: ["form-question-defs", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getFormQuestionDefs(claimId);
      return response.data;
    },
    gcTime: 0,
  });

  const formQuestionDefs = formQuestionDefsQuery.data || [];
  const claimQuery = useQuery({
    queryKey: ["claim", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimById(claimId);
      return response.data;
    },
    gcTime: 0,
  });

  if (formQuestionDefsQuery.error || claimQuery.error) {
    return <div>Something went wrong, please try again later.</div>;
  }

  const hasQuestionThatIsRequireButUnanswered = () => {
    const unansweredQuestions = formQuestionDefs.filter((question) => {
      if (!question.isRequired) return false;
      // question is required down here
      const questionHasResponse = Boolean(formQuestionResponses[question.id]);
      if (!questionHasResponse) {
        return true;
      }
    });

    return unansweredQuestions.length > 0;
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          paddingX: 3,
          paddingY: 1,
          flex: 1,
          overflow: "auto",
        }}
      >
        {continueRequestPending || formQuestionDefsQuery.isLoading || claimQuery.isLoading || claimQuery.isFetching ? (
          <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack direction={"row"}>
              <Typography sx={{ fontWeight: 600, fontSize: "1.25rem" }}>We'll need to know a little more</Typography>
              <HelpIcon helpText="Please answer the following questions to help us better understand your claim and process your claim as quickly as possible." />
            </Stack>
            <FormQuestions
              formQuestionDefs={formQuestionDefsQuery.data || []}
              initialFormQuestionResponses={claimQuery.data?.formQuestionResponses || []}
            />
          </>
        )}
      </Stack>
      <ActionButtonsFooter
        onContinueButtonClick={continueToNextStep}
        disabled={hasQuestionThatIsRequireButUnanswered()}
        disabledTooltip="Please answer required questions"
      />
    </>
  );
}
export { UserQuestions };
