import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material";
import { theme } from "@safelease/components";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import { appConfig } from "./config/app.config.ts";
import { pdfjs } from "react-pdf";

/**
 * We utilize react-pdf to display PDFs in the application.
 * For React-PDF to work, PDF.js worker needs to be provided. This is the recommended way to do it according to the docs.
 * Read more here: https://github.com/wojtekmaj/react-pdf/blob/v7.x/packages/react-pdf/README.md
 * NOTE: We are using the legacy worker of PDF.js to support older versions of browsers as recommended by the react-pdf docs.
 */
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/legacy/build/pdf.worker.min.js", import.meta.url).toString();

/**
 * This is the main entry point for the application.
 * It sets up the Amplify configuration so that the app can use AWS cognito services to manage user authentication.
 * It also sets up the Sentry configuration for error tracking.
 */
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: appConfig.aws.cognitoAppClientId,
      userPoolId: appConfig.aws.cognitoUserPoolId,
    },
  },
});

if (appConfig.environment !== "development") {
  Sentry.init({
    environment: appConfig.environment,
    dsn: "https://32c4c47eed1cb8789d1e722660515fdb@o1109446.ingest.us.sentry.io/4507426430648320",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false, // This will prevent the masking of text in Sentry
        maskAllInputs: true, // This will mask all inputs in Sentry
        blockAllMedia: true, // This will block images from loading so they don't get sent to Sentry
      }),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: ["production"].includes(appConfig.environment) ? 1.0 : 0.0, // Capture 100% of the sessions in production
    replaysOnErrorSampleRate: ["production", "demo"].includes(appConfig.environment) ? 1.0 : 0.0, // Capture 100% of the errors in production and demo
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);
