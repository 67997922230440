import { Box } from "@mui/material";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { HomePage } from "../HomePage";
import { ManageClaims } from "../claim-portal/ManageClaims";
import { SelectedClaimPage } from "../claim-portal/SelectedClaimPage";
import { PrivatePolicyForm } from "../PrivatePolicyForm";
import { NavbarWrapper } from "./NavbarWrapper";
import { ManagePrivatePolicies } from "../ManagePrivatePolicies";
import { useAuthStore } from "../hooks/useAuthStore.ts";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

type MainDashboardRouterProps = {};

function MainDashboardRouter({}: MainDashboardRouterProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const location = useLocation();

  // If the user is debugging enabled, we start the Sentry replay if it is not already started
  useEffect(() => {
    const isDebuggingEnabled = userAttributes?.isDebuggingEnabled === "true";
    if (isDebuggingEnabled) {
      const replay = Sentry.getReplay();
      if (replay && !replay.getReplayId()) {
        replay.start();
      }
    }
  }, []);

  // Flush the Sentry replay buffer whenever the route changes
  useEffect(() => {
    const replay = Sentry.getReplay();
    if (replay) {
      replay.flush(); // Flushing the replay sends the captured data to Sentry
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <NavbarWrapper>
              <HomePage />
            </NavbarWrapper>
          }
        />
        <Route
          path="/manage-claims"
          element={
            <NavbarWrapper>
              <ManageClaims />
            </NavbarWrapper>
          }
        />
        <Route path="/claim/:claimId" element={<SelectedClaimPage />} />
        <Route path="/manage-private-policies" element={<ManagePrivatePolicies />} />
        <Route path="/private-policy" element={<PrivatePolicyForm />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Box>
  );
}

export { MainDashboardRouter };
