import { create } from "zustand";

export enum FormContentType {
  LOGIN = "login",
  SIGN_UP = "signUp",
  FORGOT_PASSWORD = "forgotPassword",
}
type AuthenticationPageStore = {
  currentFormContentType: FormContentType;
  setCurrentFormContextType: (currentFormContentType: FormContentType) => void;
  email: string;
  setEmail: (email: string) => void;
  needsPasswordReset: boolean;
  setNeedsPasswordReset: (needsPasswordReset: boolean) => void;
};

export const useAuthenticationPageStore = create<AuthenticationPageStore>()((set) => ({
  currentFormContentType: FormContentType.SIGN_UP,
  setCurrentFormContextType: (currentFormContentType) => {
    set({ currentFormContentType });
  },
  needsPasswordReset: false,
  setNeedsPasswordReset: (needsPasswordReset: boolean) => {
    set({ needsPasswordReset });
  },
  email: "",
  setEmail: (email: string) => {
    set({ email });
  },
}));
