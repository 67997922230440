import mixpanel from "mixpanel-browser";
import { UserAttributes } from "../hooks/useAuthStore";
import { appConfig } from "../config/app.config";
import * as Sentry from "@sentry/react";
import { version } from "../../package.json";

export const MIXPANEL_EVENTS = {
  LOCATION_SEARCH_STEP_SUBMITTED: "Location Search Step Submitted",
  LOCATION_SEARCH_STEP_SUBMITTED_FAILED_TO_MATCH: "Location Search Step Submitted Failed to Match",
  PRIVATE_POLICY_FORM_VIEWED: "Private Policy Form Viewed",
  PRIVATE_POLICY_FORM_LOCATION_UNIT_MATCHING_SUBMITTED: "Private Policy Form Location and Unit Matching Submitted",
  PRIVATE_POLICY_FORM_LOCATION_UNIT_MATCHING_RESET: "Private Policy Form Location and Unit Matching Reset",
  PRIVATE_POLICY_FORM_SUBMITTED: "Private Policy Form Submitted",
  PRIVATE_POLICY_FORM_SUBMITTED_ERROR: "Private Policy Form Submitted Error",
  PRIVATE_POLICY_FORM_SUBMITTED_SUCCESS: "Private Policy Form Submitted Success",
  PRIVATE_POLICY_FORM_EXPLORE_COVERAGE_OPTIONS_BUTTON_CLICKED: "Private Policy Form Explore Coverage Options Button Clicked",
  PRIVATE_POLICY_FORM_EXPLORE_COVERAGE_OPTIONS_BUTTON_SKIPPED: "Private Policy Form Explore Coverage Options Button Skipped",
  PRIVATE_POLICY_FORM_SUBMISSION_CONFIRMATION_VIEWED: "Private Policy Form Submission Confirmation Viewed",
  PRIVATE_POLICY_FORM_EXPLORE_COVERAGE_OPTIONS_SHOWN: "Private Policy Form Explore Coverage Options Shown",
  PRIVATE_POLICY_FORM_ENROLL_IN_SELECTED_PLAN_BUTTON_CLICKED: "Private Policy Form Enroll In Selected Plan Button Clicked",
  PRIVATE_POLICY_MODAL_LAUNCHED_FROM_ARCHIVE_BUTTON_CLICKED: "Private Policy Modal Launched From Archive Button Clicked",
  PRIVATE_POLICY_MODAL_LAUNCHED_FROM_PROTECTION_PLAN_BUTTON_CLICKED: "Private Policy Modal Launched From Protection Plan Button Clicked",
};

if (appConfig.environment === "production" || appConfig.mixpanel.lowerEnvironmentEnabled) {
  mixpanel.init(appConfig.mixpanel.token);
  mixpanelDevLogger("Mixpanel Initialized");
}

function mixpanelDevLogger(message: string, ...args: any[]) {
  if (appConfig.environment !== "production" && appConfig.mixpanel.devLoggerEnabled) {
    console.log(`[Mixpanel]: ${message}`, ...args);
  }
}

export function mixpanelIdentify(userAttributes: UserAttributes): void {
  try {
    if (appConfig.environment === "production" || appConfig.mixpanel.lowerEnvironmentEnabled) {
      mixpanel.identify(userAttributes.sub);
      const userAttributesForMixpanel = getUserAttributesForMixpanelProperties(userAttributes);

      mixpanel.people.set({
        ...userAttributesForMixpanel,
        $name: `${userAttributesForMixpanel.given_name} ${userAttributesForMixpanel.family_name?.substring(0, 1)}`,
        $email: userAttributesForMixpanel.email,
      });

      mixpanel.register({
        ...userAttributesForMixpanel,
        ...getSystemContext(),
      });
      mixpanelDevLogger(`Mixpanel Identified - ${userAttributes.sub}`);
    }
  } catch (error) {
    console.error(`Error identifying user in Mixpanel: ${error}`);
    Sentry.captureException(error);
  }
}

export function mixpanelEventHandler(event: string, properties?: object): void {
  if (appConfig.environment !== "production") {
    mixpanelDevLogger(`${!appConfig.mixpanel.lowerEnvironmentEnabled ? "Skip" : ""} Event Tracked - `, event, properties);
    if (appConfig.mixpanel.lowerEnvironmentEnabled) {
      mixpanel.track(event, properties);
    }
  } else {
    mixpanel.track(event, properties);
    mixpanelDevLogger(`Event Tracked - ${event}`);
  }
}

export function mixpanelReset(): void {
  mixpanel.reset();
  mixpanelDevLogger("Reset");
}

function getSystemContext() {
  return {
    packageVersion: version,
    environment: appConfig.environment,
  };
}

function getUserAttributesForMixpanelProperties(userAttributes: UserAttributes) {
  const mixpanelProperties: any = {};

  const userAttributesToSkip = ["phone_number", "family_name"];
  if (userAttributes) {
    for (const [key, val] of Object.entries(userAttributes)) {
      if (!userAttributesToSkip.includes(key)) {
        mixpanelProperties[key] = val;
      }
    }
  }

  return {
    ...mixpanelProperties,
    name: `${userAttributes.given_name} ${userAttributes.family_name?.substring(0, 1)}`,
  };
}
