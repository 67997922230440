import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import "react-phone-number-input/style.css";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";
import { signUp, signIn } from "aws-amplify/auth";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { AuthServiceApi } from "../utils/apiInstances/AuthServiceApiInstance";
import { useForgotPasswordPageStore, ForgotPasswordStages } from "../AuthenticationPage/ForgotPasswordPages/useForgotPasswordPageStore";

export type PasswordlessSignUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const validationSchema = z.object({
  email: z.string().trim().min(1, "Email is required.").email(), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
  firstName: z.string().trim().min(1, "First name is required."), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
  lastName: z.string().trim().min(1, "Last name is required."), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
});

type PasswordlessSignUpFormProps = {};

function PasswordlessSignUpForm({}: PasswordlessSignUpFormProps) {
  const setCurrentFormContextType = useAuthenticationPageStore((state) => state.setCurrentFormContextType);
  const setNeedsPasswordReset = useAuthenticationPageStore((state) => state.setNeedsPasswordReset);
  const setForgotPasswordStage = useForgotPasswordPageStore((state) => state.setForgotPasswordStage);

  const [claimSubmissionLoading, setClaimSubmissionLoading] = useState<boolean>(false);
  const [optOutLoading, setOptOutLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { email, setEmail } = useAuthenticationPageStore((state) => state);

  const useFormMethods = useForm<PasswordlessSignUpFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: email,
    },
    resolver: zodResolver(validationSchema),
  });
  const { formState, handleSubmit } = useFormMethods;

  const onClaimSubmissionButtonClicked: SubmitHandler<PasswordlessSignUpFormValues> = async (data) => {
    onSignupButtonClicked(data, "/manage-claims");
  };

  const onOptOutButtonClicked: SubmitHandler<PasswordlessSignUpFormValues> = async (data) => {
    onSignupButtonClicked(data, "/private-policy");
  };

  const onSignupButtonClicked = async (data: PasswordlessSignUpFormValues, navigateTo: string) => {
    if (navigateTo === "/manage-claims") {
      setClaimSubmissionLoading(true);
    } else if (navigateTo === "/private-policy") {
      setOptOutLoading(true);
    }
    setEmail(data.email);

    const sanitizedData = {
      ...data,
      email: data.email.trim(), // removing all leading and trailing white spaces from the email
      firstName: data.firstName.trim(), // removing all leading and trailing white spaces from the first name
      lastName: data.lastName.trim(), // removing all leading and trailing white spaces from the last name
    };

    try {
      // Generating a random password for the user to immediately sign in with uuidv4 that passes cognito's password policy
      const password = uuidv4().substring(0, 13).toUpperCase() + uuidv4().substring(0, 13).toLowerCase();
      await signUp({
        username: sanitizedData.email,
        password: password,
        options: {
          userAttributes: {
            email: sanitizedData.email,
            given_name: sanitizedData.firstName,
            family_name: sanitizedData.lastName,
            phone_number: "+10000000000", // phone number is not required for passwordless sign up, but we need to pass a default fake number to cognito
            "custom:needsPasswordReset": "true",
            "custom:textCommsAccepted": "false",
            "custom:isEmailVerified": "false",
          },
        },
      });
      await signIn({ username: sanitizedData.email, password: password });
      navigate(navigateTo);
    } catch (error: any) {
      console.log(error);
      if (error?.name === "UsernameExistsException") {
        const response = await AuthServiceApi.getTenantPasswordResetStatusByEmail(sanitizedData.email);
        if (response.data?.needsPasswordReset === true) {
          enqueueSnackbar("A user with this email already exists. Please set a password.", { variant: "warning" });
          setCurrentFormContextType(FormContentType.FORGOT_PASSWORD);
          setForgotPasswordStage(ForgotPasswordStages.EMAIL_SUBMIT);
          setNeedsPasswordReset(true);
        } else {
          enqueueSnackbar("A user with this email already exists. Please login.", { variant: "warning" });
          setCurrentFormContextType(FormContentType.LOGIN);
          setNeedsPasswordReset(false);
        }
      } else {
        enqueueSnackbar(error?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
      }
    } finally {
      if (navigateTo === "/manage-claims") {
        setClaimSubmissionLoading(false);
      } else if (navigateTo === "/private-policy") {
        setOptOutLoading(false);
      }
    }
  };

  return (
    <FormProvider {...useFormMethods}>
      <form style={{ height: "100%" }}>
        <Stack justifyContent="space-between" height="100%" padding={{ xs: 2, sm: 0 }}>
          <Stack spacing={{ xs: 1, sm: 2 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Get Started</Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2 }}>
              <StyledSafeLeaseFormTextField
                name="firstName"
                helperText={formState.errors.firstName?.message}
                error={!!formState.errors.firstName?.message}
                label="First name"
                placeholder="Ex: Alex"
              />
              <StyledSafeLeaseFormTextField
                name="lastName"
                helperText={formState.errors.lastName?.message}
                error={!!formState.errors.lastName?.message}
                label="Last name"
                placeholder="Ex: Johnson"
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Box flex={1}>
                <StyledSafeLeaseFormTextField
                  name="email"
                  helperText={formState.errors.email?.message}
                  error={!!formState.errors.email?.message}
                  label="Email"
                  labelProps={{
                    sx: {
                      fontSize: 14,
                      fontWeight: 500,
                    },
                  }}
                  placeholder="Ex: Alex@gmail.com"
                />
              </Box>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <SafeLeaseButton
              onClick={handleSubmit(onClaimSubmissionButtonClicked)}
              variant="contained"
              color="navy"
              sx={{ width: "100%" }}
              loading={claimSubmissionLoading}
            >
              Claim Submission
            </SafeLeaseButton>
            <SafeLeaseButton
              onClick={handleSubmit(onOptOutButtonClicked)}
              variant="contained"
              color="navy"
              sx={{ width: "100%" }}
              loading={optOutLoading}
            >
              Opt-out of SafeLease Coverage
            </SafeLeaseButton>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A200, fontSize: 14, fontWeight: 500 }}>
                Already have an account?
              </Typography>
              <Typography
                id="e2e-login-link-from-signup-form"
                onClick={() => setCurrentFormContextType(FormContentType.LOGIN)}
                sx={{ color: "#031E30", cursor: "pointer", fontSize: 14, fontWeight: 500 }}
              >
                Login
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

const StyledSafeLeaseFormTextField = forwardRef((props: any, ref) => {
  return (
    <SafeLeaseFormTextField
      inputRef={ref}
      labelProps={{
        sx: {
          fontSize: 14,
          fontWeight: 500,
        },
      }}
      {...props}
    />
  );
});

export { PasswordlessSignUpForm };
