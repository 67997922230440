import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import { Claim_ClaimsDbModel, FormQuestionDef_ClaimsDbModel, TypeSpecificAttachmentDef_ClaimsDbModel } from "@safelease/service-utilities";

type CountOutstandingDocumentsProps = {
  claimData: Claim_ClaimsDbModel;
  typeSpecificAttachmentDefs: TypeSpecificAttachmentDef_ClaimsDbModel[];
  formQuestionDefs: FormQuestionDef_ClaimsDbModel[];
};

function CountOutstandingDocuments({ claimData, typeSpecificAttachmentDefs, formQuestionDefs }: CountOutstandingDocumentsProps) {
  // function to determine if the claim has any outstanding documents or questions
  const getOutstandingDocumentsAndQuestionsCount = () => {
    // sets the intial state for the document count and the question count
    let documentCount = 0;
    let questionCount = 0;

    // Increment documentCount if there's no valid billing ledger document The Number() function converts the boolean result of the some() method to 0 or 1
    documentCount += Number(!claimData?.billingLedgerDocs?.some((file) => file.attachment && !file.attachment.deletedAt));
    // Increment documentCount if there's no valid protection plan addendumn docs The Number() function converts the boolean result of the some() method to 0 or 1
    documentCount += Number(!claimData?.protectionPlanAddendumDocs?.some((file) => file.attachment && !file.attachment.deletedAt));

    //Filter for Type Specific Attachements Questions that have not been uploaded
    const typeSpecificQuestionWithoutAttachements = typeSpecificAttachmentDefs?.filter(
      (question) => !claimData?.typeSpecificAttachments?.some((attachements) => attachements.typeSpecificAttachmentDefId === question.id),
    );
    // Increment documentCount by the number of type specific questions that have not been uploaded
    documentCount += typeSpecificQuestionWithoutAttachements.length;

    // Increment questionCount if there are outstanding questions that have not been answered.
    questionCount +=
      formQuestionDefs?.filter(
        (question) => !claimData?.formQuestionResponses?.some((response) => response.formQuestionDefId === question.id),
      ).length || 0;

    // Variable for line items so if more than one line item is uploaded the number of attachements can be increased by the number of line items
    const lineItems = claimData?.lineItems ?? [];

    // Counts line items missing proof of ownership or purchase files that have not been previously deleted
    const outstandingLineItemFilesCount = (fileType: "proofOfOwnershipFiles" | "proofOfPurchaseFiles") =>
      lineItems.filter((item) => !item[fileType]?.some((file) => file.attachment && !file.attachment.deletedAt)).length;

    // Increment documentCount if there's no valid proof of ownership or proof of purchase for any line item
    documentCount += outstandingLineItemFilesCount("proofOfOwnershipFiles");
    documentCount += outstandingLineItemFilesCount("proofOfPurchaseFiles");

    return { documentCount, questionCount };
  };

  const { documentCount, questionCount } = getOutstandingDocumentsAndQuestionsCount();
  const totalCount = documentCount + questionCount;

  {
    /* checks to see the document and question counts and determines if the text should be plural or singular  */
  }
  return totalCount > 0 ? (
    <Stack
      direction="row"
      borderLeft={"4px solid red"}
      borderRadius={2}
      alignItems={"center"}
      spacing={1}
      width={"100%"}
      padding={1}
      marginLeft={2}
      marginTop={1}
    >
      <InfoIcon color="error" fontSize="medium" sx={{ paddingLeft: 1 }} />

      <Typography fontSize={12} width="100%">
        <strong>
          You have
          {documentCount > 0 && ` ${documentCount} missing ${documentCount === 1 ? "document" : "documents"}`}
          {documentCount > 0 && questionCount > 0 && " and "}
          {questionCount > 0 && ` ${questionCount} unanswered ${questionCount === 1 ? "question" : "questions"}`}
        </strong>
        {totalCount > 0 && `. Not providing ${totalCount > 1 ? "these" : "this"} may delay the processing of your claim.`}
      </Typography>
    </Stack>
  ) : (
    //  If there are no outstanding documents or questions, change the style of the icon and the text
    <Stack
      direction="row"
      borderLeft={"4px solid green"}
      borderRadius={2}
      alignItems={"center"}
      spacing={1}
      width={"100%"}
      padding={1}
      marginLeft={2}
      marginTop={1}
    >
      <CheckCircleIcon color="success" fontSize="medium" sx={{ paddingLeft: 1 }} />
      <Typography fontSize={12} width="100%">
        <strong>Everything looks good from our end, you're all set!</strong>
      </Typography>
    </Stack>
  );
}

export { CountOutstandingDocuments };
